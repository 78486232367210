// AWS Amplify Configuration Template - autogenerated at deploy time
/* eslint-disable no-template-curly-in-string */
const config = {
    domain: "https://app-vendor.xfaktor.backoffice.semigator.de",
    s3: {
        REGION: "eu-central-1",
        BUCKET: "xfaktor-vendor-upload",
    },
    apiGateway: {
        REGION: "eu-central-1",
        URL: "https://ggk7iwcqgh.execute-api.eu-central-1.amazonaws.com/prod/v1",
    },
    // Support tenant cognito config
    cognito: {
        REGION: "eu-central-1",
        USER_POOL_ID: "eu-central-1_MZvq4RrBH",
        APP_CLIENT_ID: "7qst6h4eoo9p04nj11hder47dl",
    },
    locales: {
        URL: "https://locales.xfaktor.backoffice.semigator.de",
    },
    auth: {
        ONEX_AUTH_URL: "https://auth.one.platform.haufe.io/v1",
    },
    semcore: {
        PROXY_URL: "https://www.semigator.de/xfaktor-vendor-app",
    },
    emails: {
        FROM_XFAKTOR: "Partner Management <partner@semigator.de>",
    },
    officialDomain: {
        DOMAIN: "partner.semigator.de",
    },
    stage: {
        ENV: "prod",
    },
};
/* eslint-enable no-template-curly-in-string */

export default config;
